<template>
    <div class="change-box">
        <div class="content-title">商户信息</div>
        <el-row>
            <el-col :span="3" class="item-title">商户编号</el-col>
            <el-col :span="12" class="item-value">
                {{merchantNo}}
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="3" class="item-title">商户姓名</el-col>
            <el-col :span="12" class="item-value">
                {{merchantName}}
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12" class="item-value">
                <table class="rate-table" style="width:950px" v-if="statusData">
						<tr>
							<th>业务类型</th>
							<th>卡类型</th>
							<th>T1成本（比例：%，封顶：元）</th>
							<th>T0成本（%）</th>
							<th>T0提现费（元）</th>
						</tr>
						<tr v-for="item in statusData" :key="item.id">
							<td>{{item.payTypeCode | payType}}</td>
							<td>{{item.cardType | formateCardType}}</td>
							<td>
								<div>
									<span v-if="item.payTypeCode=='POS'&&item.cardType=='2'">比例：</span>
									<template>{{item.beforeT1Rate}}</template>
								</div>
								<div v-if="item.payTypeCode=='POS'&&item.cardType=='2'">
									<template>封顶:{{item.beforeT1TopFee}}</template>
                                </div>
							</td>
							<td>
								<template>{{item.beforeT0Rate}}</template>
							</td>
							<td>
								<div v-if="item.payTypeCode=='POS'&&item.cardType=='1'">
									<template>{{item.beforeCashFee}}</template>
									</div>
									<span v-else>-</span>
								</td>
						</tr>
					</table>
            </el-col>
        </el-row>
        <br>
        <div class="content-title">变更申请</div>
        <el-row>
            <el-col :span="12" class="item-value">
                <table class="rate-table" style="width:950px" v-if="statusData">
						<tr>
							<th>业务类型</th>
							<th>卡类型</th>
							<th>T1成本（比例：%，封顶：元）</th>
							<th>T0成本（%）</th>
							<th>T0提现费成本（元）</th>
						</tr>
						<tr v-for="item in statusData" :key="item.id">
							<td>{{item.payTypeCode | payType}}</td>
							<td>{{item.cardType | formateCardType}}</td>
							<td>
                                <template v-if="item.afterT1Rate">
                                    <div>
                                        <span v-if="item.payTypeCode=='POS'&&item.cardType=='2'">比例：</span>
                                        <template>{{item.afterT1Rate}}</template>
                                    </div>
                                    <div v-if="item.payTypeCode=='POS'&&item.cardType=='2'">
                                        <template>封顶:{{item.afterT1TopFee}}</template>
                                    </div>
                                </template>
                                <template v-else>-</template>
								
							</td>
							<td>
                                <template v-if="item.afterT0Rate">
								    <template>{{item.afterT0Rate}}</template>
                                </template>
                                <template v-else>-</template>
								
							</td>
							<td>
								<div v-if="item.payTypeCode=='POS'&&item.cardType=='1'">
									<template>{{item.afterCashFee}}</template>
									</div>
									<span v-else>-</span>
								</td>
						</tr>
					</table>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { MerchantApi} from '@/api'
import CostInput from '@/components/costInput'
export default {
    data(){
        return{
            statusData:{merchantNo:''},
            merchantNo:'',
            merchantName:'',
            applyNo:''
        }
    },
    components:{
        CostInput
    },
    created(){
        this.isAdd = this.$route.query.isAdd ? true : false
        this.applyNo = this.$route.query.applyNo
        this.merchantNo = this.$route.query.merchantNo
        this.merchantName = this.$route.query.merchantName
        MerchantApi.merChangeApply.queryChangeApplyDetail(this.applyNo)
            .then(res=>{
                if(res.success){
                    this.statusData = res.data.merRateChangeApplyDetails
                }
            })
    },
}
</script>